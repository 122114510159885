import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import React from 'react';
import './ByLine.scss';

export default function ByLine({ author, publishDate }) {
  const formattedDate = new Date(publishDate).toLocaleDateString();
  const authorKabab = author.toLowerCase().replace(/\s/g, '-');
  return (
    <div className="by-line">
      <address className="by-line__author">
        By:{' '}
        <Link
          to={`/author/${authorKabab}`}
          rel="author"
          className="by-line__author-link"
        >
          {author}
        </Link>
      </address>
      <time dateTime={publishDate}>{formattedDate}</time>
    </div>
  );
}

ByLine.propTypes = {
  author: PropTypes.string,
  publishDate: PropTypes.any,
};
